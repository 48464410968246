import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom"; 
import './Header.css';

const Header = () => {
  const location = useLocation(); // Get the current location
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [activeNav, setActiveNav] = useState('');

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  useEffect(() => {
    // Set the active tab based on the current path
    const pathMap = {
      '/': 'home',
      '/About': 'about',
      '/WhyFounderValley': 'whyFounderValley',
    };

    setActiveNav(pathMap[location.pathname] || ''); // Default to empty if path not found
  }, [location.pathname]);

  const handleNavClick = (nav) => {
    setActiveNav(nav);
  };

  return (
    <header className="header">
      <div className="div2">
        <div className="div3">
          <div className="column">
            <div className="div4">
            <Link to="/" >
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/5c80ab5686f941b497667b67251e411e/3a8a2e14c0cb470d92311ac791c050ad34478803a4f749ebb0c2e0fd218e62c3?apiKey=5c80ab5686f941b497667b67251e411e&width=2000"
                  className="img"
                  alt="Logo" // Add an alt attribute for accessibility
                />
              </Link>
            </div>
          </div>
          <div className="column2">
            <div className="div6">
              <button className="hamburger" onClick={toggleMenu}>
                &#9776; {/* Hamburger icon */}
              </button>
              <div className={`div7 ${isMenuOpen ? 'open' : ''}`}>
                <Link 
                  to="/" 
                  className={`home ${activeNav === 'home' ? 'active' : ''}`}
                  onClick={() => handleNavClick('home')}
                >
                  Home
                </Link>
                <Link 
                  to="/About" 
                  className={`aboutUs ${activeNav === 'about' ? 'active' : ''}`}
                  onClick={() => handleNavClick('about')}
                >
                  About Us
                </Link>
                <Link 
                  to="/WhyFounderValley" 
                  className={`whyFounderValley ${activeNav === 'whyFounderValley' ? 'active' : ''}`}
                  onClick={() => handleNavClick('whyFounderValley')}
                >
                  Why Founder Valley
                </Link>
                <Link 
                  to="/Contact" 
                  className="contact"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
