import React from 'react';
import styles from './ProcessStep.module.css';

const ProcessStep = ({ number, title, description }) => {
  return (
    
    <div className={styles.step}>
      <div className={styles.number}>{number}</div>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
      
    </div>
  );
};

export default ProcessStep;