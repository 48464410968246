import React from 'react';
import styles from './WhyUs.module.css';
import ReasonCard from './ReasonCard';
import icons from '../../Assets/icon11.png'
import icons1 from '../../Assets/icon22.png'
import icons2 from '../../Assets/icon33.png'
import { useNavigate } from 'react-router-dom';

const WhyUs = () => {
  const reasons = [
    {
      icon: icons,
      title: "Expertise",
      description: "We are a team of experienced investors, strategists, and technologists with a proven record of helping startups succeed. Our guidance is based on real-world experience, not just theory."
    },
    {
      icon: icons1,
      title: "Comprehensive Support",
      description: "Beyond funding, we offer hands-on technical development and strategic advice tailored to your startup's needs. This ensures complete growth support.."
    },
    {
      icon: icons2,
      title: "Global Network",
      description: "In partnership with industry leaders, potential partners, and investors, we help startups access valuable insights, resources, and opportunities that accelerate growth.."
    }
  ];
  const navigate = useNavigate(); // Get the navigate function from React Router

  const handleNavigation = () => {
    navigate('/Contact'); // Navigate to the "About Me" page
  };
  return (
    <section className={styles.whyUs}>
      <h2 className={styles.title}>Why Founder Valley?</h2>
      <h2 className={styles.title1}>Why Founder Valley?</h2>

      <div className={styles.reasonsContainer}>
        {reasons.map((reason, index) => (
          <ReasonCard key={index} {...reason} />
        ))}
      </div>
      <p className={styles.whySummary}>At Founder Valley, we're not just investors. We are partners in your journey, committed to helping you overcome obstacles and achieve success.</p>
      <p className={styles.whyCta}>Ready to take the leap? Let's make your vision happen</p>
      <button className={styles.ctaButton}  onClick={handleNavigation}>Make it happen</button>
    </section>
  );
};

export default WhyUs;