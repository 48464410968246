import React from 'react';
import styles from '../AboutsCss/AboutSection.module.css'
import Servicesecttor from'./Serviceesector'

function AboutSection() {
  return (
    <section className={styles.aboutSection}>
     <Servicesecttor/>
    </section>
  );
}

export default AboutSection;