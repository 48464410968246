import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from '../ContactComponenet/ContactPage'
import WhyFounderValley from '../Why-Founder-Valley-dark/WhyFounderjs/WhyFounderValley';
import Home from '../HomeComponents/Hero/Hero'
import  About from '../AboutsComponents/AboutsJs/AboutPage'

function Navigation() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/About" element={< About/>} />
                <Route path="/WhyFounderValley" element={<WhyFounderValley />} />
                <Route path="/Contact" element={< Contact/>} />
            </Routes>
        </Router>
    );
}

export default Navigation;
