import * as React from "react";
import { useState } from "react"; 
import './ContactPages.css'; // Importing regular CSS
import Header from "../HeaderComponenet/Header";
import Footer from '../FooterComponent/Footer';
import styles from '../AboutsComponents/AboutsCss/AboutPage.module.css';

function MyComponent() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const [status, setStatus] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    
    console.log("Form data before submission:", formData);
    
    if (!formData.name || !formData.email || !formData.message) {
      setStatus("Please fill in all fields.");
      return;
    }

    try {
      const formDataObj = new FormData();
      formDataObj.append('name', formData.name);
      formDataObj.append('email', formData.email);
      formDataObj.append('message', formData.message);

      const response = await fetch("https://foundervalley.plexigenius.com/contact.php", {
        method: "POST",
        body: formDataObj, 
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP Error: ${response.status} - ${errorText}`);
      }

      const responseData = await response.json(); 
      console.log("Response Data:", responseData);

      setStatus("Success! Your message has been sent.");
    } catch (error) {
      console.error("Error with the request:", error.message);
      setStatus(`Submission failed: ${error.message}`);
    }

    setFormData({
      name: "",
      email: "",
      message: ""
    });
  };

  return (
    <>
      <Header />
      <div className={styles.aboutPage}>
        <div className={styles.div}>
          <section className={styles.hero}>
            <div className={styles.content1}>
              <h1 className={styles.title}>
                <span className={styles.highlight1}></span> Contact Us{' '}
              </h1>
              <p className={styles.description}>
                Founder Valley empowers early-stage and struggling startups through funding, strategic guidance, technical development, and business incubation to turn challenges into opportunities. As a venture capital and private equity firm.
              </p>
            </div>
            <img
              srcSet="https://cdn.builder.io/api/v1/image/assets/5c80ab5686f941b497667b67251e411e/6686aa9f93aeb5be83dc699272173a9b82f053bf284d6b0c844fa35033b6147b?apiKey=5c80ab5686f941b497667b67251e411e&width=2000"
              alt="Innovative startup team collaborating"
              className={styles.heroImage}
            />
          </section>
        </div>
      </div>

      <div className="contactDark">
        <div className="div">
          <div className="div8">
            <div className="div9">
              <div className="column3">
                <div className="div10">
                  <div className="info">
                    <div className="getInTouch">Get in touch</div>
                    <div className="weAreHereForYou">We are here for you! How can we help?</div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="name">
                      <div className="nameLabel">Name</div>
                      <input
                        className="inputField2"
                        type="text"
                        name="name" 
                        value={formData.name}
                        onChange={handleInputChange} 
                      />
                    </div>

                    <div className="email">
                      <div className="emailLabel">Email</div>
                      <input
                        className="inputField2"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="message">
                      <div className="messageLabel">Message</div>
                      <textarea
                        className="inputField1"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>

                    <button type="submit" className="submit">Submit</button>
                  </form>

                  {status && <p className="statusMessage">{status}</p>}
                </div>
              </div>

              <div className="column4">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/5c80ab5686f941b497667b67251e411e/f7638eb4a55f90043c2ba9c930aaf054124865bd23aad8a13b9dd7aaa54bc833?apiKey=5c80ab5686f941b497667b67251e411e&"
                  className="img3"
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default MyComponent;
