import React from 'react';
import '../AboutsCss/ServiceCotor.module.css'

const ServiceCategory = ({ number, title, services }) => {
    return (
        <div className="serviceCategory">
            <div className="categoryHeader">
                <span className="categoryNumber">{number}</span>
                <h2 className="categoryTitle">{title}</h2>
            </div>
            <div className="servicesList">
                {services.map((service, index) => (
                    <div key={index} className="serviceItem">
                        <img src={service.icon} alt="" className="serviceIcon" />
                        <div className="serviceContent">
                            <h3 className="serviceTitle">{service.title}</h3>
                            <p className="serviceDescription">{service.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceCategory;
