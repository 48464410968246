import React from 'react';
import styles from '../AboutsCss/WhatSetsUsApart.module.css'
import icons from '../../Assets/icon6.png'
import icons1 from '../../Assets/icon7.png'
import icons2 from '../../Assets/icon8.png'
import icons3 from '../../Assets/icon9.png'

function WhatSetsUsApart() {
  const features = [
    {
      icon: icons,
      title: "Tailored Funding",
      description: ": We provide financial support from early-stage development to scaling.",
      description1:": We offer specialized funding to help startups pivot, refine strategies, and recover from setbacks.",
      description2:"Seed to Post-Seed Funding",
      description3:"Recovery Capita"
    },
    {
      icon: icons1,
      title: "Proven Strategies",
      description: "With 100+ years of combined experience, our team has guided over 50 startups through various growth challenges.",
      description1:"We employ data-driven strategies, focusing on market trends, profitability, and scalability."
    },
    {
      icon: icons2,
      title: "Advanced Tech Solutions",
      description: ": We build software that aligns with your business model, improving workflows and user experience.",
      // description1:": We implement advanced data handling systems that enhance scalability and decision-making speed.",
      description5:"Web Applications: We create seamless digital solutions that improve customer engagement and automate operations.",
         description2:"Custom Software Development",
      // description3:"Recovery Capita",
      description4:"Web Applications"


    },
    {
      icon: icons3,
      title: "Incubator Benefits",
      description: "Access to 150+ industry experts, mentors, strategic partners, and investors accelerates growth.",
      description1:"Our incubator provides startups with resources, networking, and mentorship that foster rapid development.",
      

    }
  ];

  return (
    <section className={styles.whatSetsUsApart}>
      <div className='linner'>
      <img src="https://cdn.builder.io/api/v1/image/assets/5c80ab5686f941b497667b67251e411e/b82eb4f8bd29539cf6512803a01c539ff786442eab019ed3fc8bfbcadb70705f?apiKey=5c80ab5686f941b497667b67251e411e&" alt="" className={styles.backgroundImage} />
      <h2 className={styles.sectionTitle}>What Sets Us Apart?</h2>
      </div>
      <div className={styles.featureGrid}>
        {features.map((feature, index) => (
          <div key={index} className={styles.featureCard}>
            <img src={feature.icon} alt="" className={styles.featureIcon} />
            <h3 className={styles.featureTitle}>{feature.title}</h3>
            <p className={styles.featureDescription}> <span style={{fontSize:'12px',fontWeight:'bold'}}>{feature.description2}</span>{feature.description}</p>
            <p className={styles.featureDescription}><span style={{fontSize:'10px',fontWeight:'bold'}}>{feature.description3}</span>{feature.description1}</p>
            <p className={styles.featureDescription}><span style={{fontSize:'14px',fontWeight:'bold'}}>{feature.description4}</span>{feature.description5}</p>

          </div>
        ))}
      </div>
    </section>
  );
}

export default WhatSetsUsApart;