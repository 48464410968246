import React from 'react';
import styles from '../AboutsCss/ImpactExample.module.css'

function ImpactExample() {
  return (
    <section className={styles.impactExample}>
      <h2 className={styles.impactTitle}>Example of Impact:</h2>
      <p className={styles.impactDescription}>
        Founder Valley empowered Essaly, a digital receipts platform, to refine its market fit and scale rapidly. With our funding, strategic guidance, and tech support, Essaly secured partnerships with leading POS providers, expanding across Saudi Arabia. Today, Essaly leads in digital receipts, offering secure, eco-friendly solutions and providing users with valuable financial insights—an achievement powered by Founder Valley's hands-on approach.
      </p>
    </section>
  );
}

export default ImpactExample;