import React from 'react';
import '../WhyFoundercss/ServicesSection.css'
import ServiceCategory from './ServiceCategory'
import icons from '../../Assets/icon10.png'

const ventureCapitalServices = [
    {
        icon: icons,
        title: "Funding Across Stages",
        description: "We invest at various stages of growth, providing capital tailored to each startup's specific needs. For early-stage startups, we offer seed capital to develop prototypes and test ideas. For more mature startups, we provide growth capital to expand operations and improve market reach."
    },
    {
        icon: icons,
        title: "Recovery Capital",
        description: "Startups facing challenges with market acceptance or other setbacks receive specialized funding to help them pivot, refine their strategy, and regain market momentum."
    },
    {
        icon: icons,
        title: "Flexible Investment",
        description: "We offer a variety of investment options, including equity financing, convertible notes, and debt financing, to match the unique requirements of your business model."
    }
];

const technicalDevelopmentServices = [
    {
        icon: icons,
        title: "Software Development",
        description: "We develop custom software that aligns with your business needs, creating better workflows, enhancing user experience, and supporting growth. Whether you need mobile apps, web platforms, or backend systems, we build the right tools for your business"
    },
    {
        icon: icons,
        title: "Database Optimization",
        description: "We enhance data handling systems to ensure better management, scalability, and faster data processing. This helps startups make informed decisions and handle increasing user demands smoothly."
    },
    {
        icon: icons,
        title: "Web Applications",
        description: "Our web-based solutions help businesses interact more effectively with customers, automate processes, and offer digital services seamlessly. This includes building user-friendly interfaces, secure payment gateways, and integrating third-party services."
    }
];

const businessIncubationServices = [
    {
        icon: icons,
        title: "Personalized Mentorship",
        description: "Our mentors, including experienced entrepreneurs and industry experts, guide you in areas like market entry, product development, marketing strategies, and scaling operations. This mentorship helps startups understand challenges and explore new growth opportunities."
    },
    {
        icon: icons,
        title: "Networking Opportunities",
        description: "Startups gain access to our extensive network, including investors, strategic partners, and industry leaders. This network facilitates partnerships, funding opportunities, and potential collaborations."
    },
    {
        icon: icons,
        title: "Resource Allocation",
        description: "We provide startups with essential tools, from project management software to marketing platforms, ensuring they have the right resources to scale efficiently."
    }
];

const ServicesSection = () => {
    return (
        <section className="servicesSection">
            <div className="serviceCategory1 rightAlign">
                <ServiceCategory
                    number="01"
                    title="Venture Capital & Private Equity"
                    services={ventureCapitalServices}
                />
              
            </div>
            <div className="serviceCategory2 rightAlign">
               
                <ServiceCategory
                    number="02"
                    title="Technical Development"
                    services={technicalDevelopmentServices}
                />
            </div>
            <div className="serviceCategory1 rightAlign">
               <ServiceCategory
                   number="03"
                   title="Business Incubation"
                   services={businessIncubationServices}
               />
           </div>
        </section>
    );
};

export default ServicesSection;