import React from 'react';
import styles from'../AboutsCss/Serviceesector.module.css'
import ServiceCategory from './ServiceCotor'
import icons from '../../Assets/111.png'
import icons1 from '../../Assets/11.png'
import icons2 from '../../Assets/222.png'

const ventureCapitalServices = [
    {
        icon: icons,
        title: "Industry Expertise",
        description: "We invest across sectors like fintech, logistics, prop-tech, e-commerce, and health-tech, focusing on high-growth and recovery opportunities.We specialize in supporting startups with market fit issues, enabling them to pivot and thrive in competitive markets."
    },

];

const technicalDevelopmentServices = [
    {
        icon: icons2,
        title: "Global Reach",
        description: "With investments spanning the MENA region, Europe, and North America, we leverage a global network to drive innovation and market expansion.Our portfolio companies benefit from strategic access to global markets, regulatory guidance, and local partnerships."
    },

];

const businessIncubationServices = [
    {
        icon: icons1,
        title: "Tech-Driven Growth",
        description: "We emphasize the integration of advanced technologies, including AI, blockchain, and cloud computing, to enhance scalability and efficiency."
    },

];

const ServicesSection = () => {
    return (
        <section className="servicesSection">
            <div className="serviceCategory11 rightAlign">
                <ServiceCategory
                    services={ventureCapitalServices}
                />

            </div>
            <div className="serviceCategory22 rightAlign">

                <ServiceCategory
                    services={technicalDevelopmentServices}
                />
            </div>
            <div className="serviceCategory11 rightAlign">
                <ServiceCategory
                    services={businessIncubationServices}
                />
            </div>
            <section className={styles.impactExample}>
                <h2 className={styles.impactTitle}>Key Industry Insights:</h2>
                <p className={styles.impactDescription}>
                According to MAGNiTT, the MENA region saw record-breaking VC investments, with $2.6 billion raised in 2021, representing a 138% growth from the previous year​                </p>
                <p className={styles.impactDescription}>

                The UAE, Saudi Arabia, and Egypt are the leading startup hubs, accounting for 60% of total deals​                </p>
                <p className={styles.impactDescription}>
                Fintech remains the most funded sector, securing 33% of all deals across the region in 2021, highlighting the importance of early-stage support for financial innovation.                </p>
            </section>
        </section>
    );
};

export default ServicesSection;