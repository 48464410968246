import React from 'react';
import styles from './Services.module.css';
import ServiceCard from '../ServiceCard/ServiceCard';
import icons from '../../Assets/icon.png'
import icons1 from '../../Assets/icon1.png'
import icons2 from '../../Assets/icon2.png'
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const services = [
    {
      icon: icons,
      title: "Venture Capital & Private Equity",
      description: " We provide tailored capital solutions from seed to growth stages.",
      description1: "We offer specialized funding to help startups recover from market challenges, pivot strategies, and regain momentum.",
      description2: " Options include equity financing, convertible notes, and debt financing, tailored to fit diverse business models..",
      bold: "Funding Across Stages: ",
      bold1: "Recovery Capital: ",
      bold2: "Flexible Investment Options: "
    },
    {
      icon: icons1,
      title: "Technical Development",
      description: "We develop custom software that aligns with your business needs, creating better workflows, enhancing user experience, and supporting growt.",
      description1: "  We enhance data handling systems to ensure better management, scalability, and faster data processing.",
      description2: " Our web-based solutions help businesses interact more effectively with customers, automate processes, and offer digital services seamlessly. .",
      bold: "Software Development: ",
      bold1: "Database Optimization: ",
      bold2: "Web Applications:: "
    },
    {
      icon: icons2,
      title: "Business Incubation",
      description: "Our mentors, including experienced entrepreneurs and industry experts, guide you in areas like market entry, product development,  .",
      description1: "Our network includes 200+ strategic partners across fintech, logistics, and tech sectors, facilitating crucial collaborations.",
      description2: "We provide startups with essential tools, from project management software to marketing platforms,",
      bold: "Personalized Mentorship: ",
      bold1: "Networking Opportunities: ",
      bold2: "Resource Allocation: "
    }
  ];
  const navigate = useNavigate(); // Get the navigate function from React Router

  const handleNavigation = () => {
    navigate('/Contact'); // Navigate to the "About Me" page
  };
  return (
    <section className={styles.services}>
      <h2 className={styles.title}>What We Offer</h2>
      <div className={styles.cardContainer}>
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
      <h2 className={styles.heroTagline}>Let's build your startup's success story together.</h2>
      <button className={styles.ctaButton} onClick={handleNavigation}>Begin Your Journey</button>
    </section>
  );
};

export default Services;