import React from 'react';
import styles from './Hero.module.css';
import Header from '../../HeaderComponenet/Header';
import Services from '../Services/Services';
import Abouts from '../About/Abouts';
import Procces from '../Procces/Proces';
import WhyUs from '../WhyUs/WhyUs';
import FAQ from '../F&Q/FAQ';
import Footer from '../../FooterComponent/Footer'
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate(); 

  const handleNavigation = () => {
    navigate('/Contact'); 
  };
  return (
    <>
      <Header />
      <div className={styles.app}>
        <section className={styles.hero}>

          <div className={styles.content}>
            <h1 className={styles.title}>
              <span className={styles.highlight}>Fueling</span> Innovation, Investing{' '}
              <span className={styles.highlight}>in</span>{' '}
              <span className={styles.highlight}>the Future</span>
            </h1>
            <p className={styles.description}>
              At Founder Valley, we empower early-stage and struggling startups by offering funding, strategic advice, tech development, and business incubation. Unlike traditional venture capital firms, we work alongside startups from pre-seed to post-seed stages, offering far-reaching help at each step. Given that nearly 90% of startups fail within the first few years, our mission is to increase your chances of success by helping you navigate obstacles, scale efficiently, and establish a strong market presence.
            </p>
            <h2 className={styles.heroTagline}>Let's build your startup's success story together.</h2>
            <button className={styles.ctaButton} onClick={handleNavigation}>Get Started with Founder Valley</button>

            </div>
          <img src="https://cdn.builder.io/api/v1/image/assets/5c80ab5686f941b497667b67251e411e/6ea95bd1d0ec4af267495522e19b5a163a1fc1fb7b8ee8856de2e45006e22fa8?apiKey=1f4bb8e8663a4758a6043df15658c112&" alt="Innovative startup team collaborating" className={styles.heroImage} />
        </section>
        <Services />
        <Abouts />
        <Procces />
        <WhyUs />
        <FAQ />

      </div>
      <Footer />

    </>
  );
};

export default Hero;