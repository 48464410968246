import React, { useState } from 'react';
import styles from './FAQ.module.css';
import FAQItem from './FAQItem';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const faqItems = [
    {
      question: "What types of startups does Founder Valley invest in?",
      answer: "We invest early-stage startups in diverse sectors, including fintech, logistics, prop-tech, and digital services. We also help startups struggling with market fit or growth issues."
    },
    {
      question: "How much funding can a startup expect?",
      answer: "Funding amounts vary based on the startup's stage, needs, and potential. We offer flexible funding solutions tailored to each startup's unique situation."
    },
    {
      question: "What other support does Founder Valley offer beyond funding?",
      answer: "We provide technical development, strategic guidance, mentorship, and access to our global network of industry experts and partners."
    },
    {
      question: "What is Founder Valley's business incubator?",
      answer: "Our business incubator offers a comprehensive program including workspace, mentorship, technical resources, and networking opportunities to help startups grow and succeed."
    },
    {
      question: "How can I apply for funding?",
      answer: "You can apply through our online application process. Visit our 'Apply' page to submit your pitch and business plan for review."
    },
    {
      question: "Is Founder Valley focused on a specific region or market?",
      answer: "While we have a global outlook, we primarily focus on emerging markets and regions with high growth potential in the tech and innovation sectors."
    }
  ];

  return (
    <section className={styles.faq}>
      <h2 className={styles.title}>FAQ</h2>
      <div className={styles.faqContainer}>
        {faqItems.map((item, index) => (
          <FAQItem
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={index === openIndex}
            onClick={() => setOpenIndex(index === openIndex ? -1 : index)}
          />
        ))}
      </div>
    </section>
  );
};

export default FAQ;