import React from 'react';
import styles from './ServiceCard.module.css';

const ServiceCard = ({ icon, title, description,description1,description2,bold,bold1,bold2 }) => {
  return (
    <div className={styles.card}>
      <img src={icon} alt={`${title} icon`} className={styles.icon} />
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}> <span  className={styles.spanss}>{bold}</span>{description}</p>
        <p className={styles.description}> <span className={styles.spanss}>{bold1}</span>{description1}</p>

        <p className={styles.description}> <span className={styles.spanss}>{bold2}</span>{description2}</p>

      </div>
    </div>
  );
};

export default ServiceCard;