import React from 'react';
import '../WhyFoundercss/WhyChooseSection.css'
import icons from '../../Assets/icon.png'
import icons1 from '../../Assets/icon1.png'
import icons2 from '../../Assets/icon2.png'
import { useNavigate } from 'react-router-dom';

const reasons = [
  {
    icon: icons,
    title: "Expert Guidance",
    description: "We are a team of experienced investors, strategists, and technologists with a proven record of helping startups succeed. Our guidance is based on real-world experience, not just theory."
  },
  {
    icon: icons1,
    title: "All-Round Support",
    description: "Beyond funding, we offer hands-on technical development and strategic advice tailored to your startup's needs. This ensures complete growth support."
  },
  {
    icon: icons2,
    title: "Extensive Global Network",
    description: "In partnership with industry leaders, potential partners, and investors, we help startups access valuable insights, resources, and opportunities that accelerate growth."
  }
];

const WhyChooseSection = () => {
  const navigate = useNavigate(); // Get the navigate function from React Router

  const handleNavigation = () => {
    navigate('/Contact'); // Navigate to the "About Me" page
  };
  return (
    <section className="whyChooseSection">
      <h2 className="sectionTitle">Why Choose Founder Valley?</h2>
      <div className="reasonsContainer">
        {reasons.map((reason, index) => (
          <div key={index} className="reasonCard">
            <img src={reason.icon} alt="" className="reasonIcon" />
            <h3 className="reasonTitle">{reason.title}</h3>
            <p className="reasonDescription">{reason.description}</p>
          </div>
        ))}
      </div>
      <p className="processSummary">This step-by-step approach provides startups with a clear path to progress, whether they're launching a new product or recovering from setbacks.</p>
      <p className="processCta">Your path to growth and resilience starts here. Let us guide you from vision to success.</p>
      <button className="ctaButton" onClick={handleNavigation}>Make it happen</button>
    </section>

  );
};

export default WhyChooseSection;