import React from 'react';
import Header from '../../HeaderComponenet/Header';
import AboutSection from '../AboutsJs/AboutSection';
import WhatSetsUsApart from '../AboutsJs/WhatSetsUsApart';
import ImpactExample from '../AboutsJs/ImpactExample';
import Footer from '../../FooterComponent/Footer';
import styles from '../AboutsCss/AboutPage.module.css';

function AboutPage() {
    return (
        <>
            <Header />
            <div className={styles.aboutPage}>
                <div className={styles.div}>
                    <section className={styles.hero}>

                        <div className={styles.content1}>
                            <h1 className={styles.title}>
                                <span className={styles.highlight1}></span> About{' '}
                                
                            </h1>
                            <p className={styles.description}>
                                Founder Valley empowers early-stage and struggling startups through funding, strategic guidance, technical development, and business incubation to turn challenges into opportunities. As a venture capital and private equity firm.                     </p>
                        </div>
                        <img srcSet="https://cdn.builder.io/api/v1/image/assets/5c80ab5686f941b497667b67251e411e/6686aa9f93aeb5be83dc699272173a9b82f053bf284d6b0c844fa35033b6147b?apiKey=5c80ab5686f941b497667b67251e411e&width=2000"
                            alt="Innovative startup team collaborating" className={styles.heroImage} />
                    </section>
                    <h1  className={styles.custom}>Our Core Focus</h1>
                    <AboutSection />
                    <WhatSetsUsApart />
                    <ImpactExample />
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default AboutPage;