import React from 'react';
import styles from './FAQItem.module.css';

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className={`${styles.faqItem} ${isOpen ? styles.open : ''}`}>
      <button className={styles.question} onClick={onClick}>
        {question}
        <span className={styles.icon}>{isOpen ? '-' : '+'}</span>
      </button>
      {isOpen && <p className={styles.answer}>{answer}</p>}
    </div>
  );
};

export default FAQItem;