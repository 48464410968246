import React from 'react';
import styles from './Abouts.module.css';

const About = () => {
  return (
    <section className={styles.about}>
      <div className={styles.imageabout}>
      <img src="https://cdn.builder.io/api/v1/image/assets/5c80ab5686f941b497667b67251e411e/7ffdd034c5831f6cd500aaed78fd6077fca794047331d5576ec9813f752560e5?apiKey=1f4bb8e8663a4758a6043df15658c112&" alt="Founder Valley team" className={styles.teamImage} />
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>About Us</h2>
        <p className={styles.description}>
          Founder Valley is a venture capital and private equity firm focused on helping startups grow and recover. Our unique approach mixes funding, technology, and strategic guidance to overcome common challenges such as market fit, growth hurdles, and setbacks. We comprehend that startup excursions can be capricious, which is the reason we offer complete supportive network to address every part of the business.
        </p>
        <p className={styles.description}>
          With capital, technical development, and strategy, Founder Valley addresses these challenges with a complete 360-degree support system. We create software, manage databases, and web application services to provide a solid technological base for healthy growth. We also provide business incubation that provides new networks to industry experts, partners, and investors and helps foster innovation.
        </p>
        <p className={styles.description}>
          With experience in investing, technologists, and strategists, we are committed to equipping founders with tailored support. We don't just fund ideas; we nurture them, helping businesses stabilize, scale, and even rebound from failure. At Founder Valley, innovation is more than a business strategy—it's a way to create lasting change, even when success seems elusive.
        </p>
      </div>
    </section>
  );
};

export default About;