import React from 'react';
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import './Footer.css';

const Footer = () => {

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'  // This ensures smooth scrolling to the top
    });
  };

  return (
    <div className="div14">
      <div className="div15">
        <div className="div16">
          <div className="column5">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/5c80ab5686f941b497667b67251e411e/4fb8e9ab24f63415ef86eb055bec14afe0f59645c6ba126a61833ee0c128387f?apiKey=5c80ab5686f941b497667b67251e411e&width=2000"
              className="img4"
            />
          </div>
          <div className="column6">
            <div className="div17">
              {/* Adding the onClick event to scroll to top when the link is clicked */}
              <Link to="/" className="home" onClick={scrollToTop}>Home</Link>
              <Link to="/About" className="aboutUs2" onClick={scrollToTop}>About Us</Link>
              <Link to="/WhyFounderValley" className="whyFounderValley2" onClick={scrollToTop}>Why Founder Valley</Link>
              <Link to="/Contact" className="contact2" onClick={scrollToTop}>Contact</Link>
            </div>
          </div>
          <div className="column7">
            <div className="div18">
              <div className="subscribe">Subscribe</div>
              <div className="exclusiveUpdates">
                Be the first to receive exclusive updates
                <br />— Sign up now!
              </div>
              <div className="inputContainer">
                <input type="email" className="inputField" placeholder="email" />
                <button className="signUpButton">Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div20" />
      <div className="footerText">
        © Founder Valley 2024 | All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
