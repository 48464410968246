import React from 'react';
import styles from './Proces.module.css';
import ProcessStep from './ProcessStep';
import { useNavigate } from 'react-router-dom';
import icons from '../../Assets/111.png'
import icons1 from '../../Assets/11.png'
import icons2 from '../../Assets/222.png'

const Process = () => {
  const steps = [
    { number: "01", title: "Submit Your Pitch", description: "Share your business idea, growth challenges, and vision through our online form. We aim to understand your goals, market potential, and barriers." },
    { number: "02", title: "Evaluation", description: "Our team reviews proposals based on innovation, market size, and scalability potential. We prioritize startups with clear growth plans or strategies to recover from setbacks." },
    { number: "03", title: "Detailed Assessment", description: "Shortlisted startups go through an in-depth analysis, which includes evaluating financial health, market potential, technical requirements, and team dynamics. " },
    { number: "04", title: "Funding & Support", description: "Approved startups receive capital, technical support, and strategic advice tailored to their needs. We also offer access to our incubator for further growth." },
    { number: "05", title: "Scale Up", description: "With our resources, mentorship, and strategic guidance, startups can address challenges, grow sustainably, and achieve long-term success." }
  ];
  const navigate = useNavigate(); // Get the navigate function from React Router

  const handleNavigation = () => {
    navigate('/Contact'); // Navigate to the "About Me" page
  };
  return (
    <section className={styles.process}>
      <h2 className={styles.title}>How It Works</h2>
      <p className={styles.subtitle}>Founder Valley's investment and support process is designed to be simple, clear, and helpful</p>
      <div className={styles.stepsContainer}>
        {steps.map((step, index) => (
          <ProcessStep key={index} {...step} />
        ))}
      </div>
      <p className={styles.processSummary}>This step-by-step approach provides startups with a clear path to progress, whether they're launching a new product or recovering from setbacks.</p>
      <p className={styles.processCta}>Your path to growth and resilience starts here. Let us guide you from vision to success.</p>
      <button className={styles.ctaButton} onClick={handleNavigation}>Submit Your Pitch</button>
    </section>
  );
};

export default Process;